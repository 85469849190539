import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
`;

export const Container = styled.div`
  max-width: 632px;
  z-index: 9999;
  display: flex;
  background: linear-gradient(180deg, #ffffff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  border: 1px solid #ccd2e3;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
  margin: auto;

  @media (max-width: 650px) {
    width: 90%;
  }
`;

export const BodyRightImage = styled.img`
  display: block;
  max-height: 100%;
  max-width: 260px;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const Body = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  padding: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #001c72;

  & a {
    color: #bc2e3e;
  }
  @media (max-width: 650px) {
    padding-inline: 16px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 0;
  font-family: 'Utopia Std';
  text-align: left;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #001c72;

  & > span {
    color: #bc2e3e;
    font-style: italic;
  }

  @media (max-width: 650px) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
    text-align: center;
    padding-inline: 5px;
  }
`;

export const BodyItems = styled.div`
  display: block;
  position: relative;
  margin-top: 20px;
  padding-left: 36px;
  & > p {
    text-align: left;
    margin: 0;
    line-height: 23px;
  }
`;

export const ItemNumber = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  background: #bc2e3e;
  border-radius: 30px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
`;

export const SubTitle = styled.p`
  margin: 0 0 2px !important;
  font-family: 'Neuzeit Grotesk - Bold', sans-serif;
  text-align: left;
  font-size: 16px;
  line-height: 23px;
  color: #001c72;
  & > span {
    color: #bc2e3e;
    font-style: italic;
  }
  & > a {
    text-decoration: none;
    display: inline-block;
  }
`;

export const LinksList = styled.ul`
  text-align: left;
  padding-left: 25px;
  margin-block: 2px 0;
  & li {
    margin-bottom: 3px !important;
  }
  & span {
    color: #bc2e3e;
  }
  @media (max-width: 650px) {
    padding-left: 12px;
  }
`;

export const CloseButton = styled.div`
  color: #001c72;
  border-radius: 100%;
  cursor: pointer;
  font-size: 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  display: flex;
  align-items: center;
  & > span {
    display: block;
    margin-left: auto;
  }
`;
