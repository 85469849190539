import React from 'react';
import Modal from '../../Modal/Modal';
import * as S from './SubPopupV2.styles';
export const SubPopupV2 = ({ show, setShowSubPopup, faqURL, pageDesign }) => {
  // const faqLink = faqURL ? faqURL : 'https://thepetlabco.com/help-center';
  return (
    show && (
      <Modal>
        <S.Wrapper>
          <S.Container>
            <S.BodyRightImage
              src="/images/img-for-subs-popup.png"
              alt="Image"
            />
            <S.Body>
              <S.CloseButton
                pageDesign={pageDesign}
                onClick={() => setShowSubPopup(false)}
              >
                <span>&times;</span>
              </S.CloseButton>
              <S.Title>
                Stay in control if you need to{' '}
                <span>delay, change or cancel</span> your subscription
              </S.Title>
              <S.BodyItems>
                <S.ItemNumber>1</S.ItemNumber>
                <S.SubTitle>Create your own Petlab Account</S.SubTitle>
                <p>It only takes 30 seconds to do</p>
              </S.BodyItems>
              <S.BodyItems>
                <S.ItemNumber>2</S.ItemNumber>
                <S.SubTitle>Access your subscription anytime</S.SubTitle>
                <S.LinksList>
                  <li>
                    <span>Cancel {''}</span>
                    with ease
                  </li>
                  <li>
                    <span>Delay {''}</span>
                    billing & shipping anytime
                  </li>
                  <li>
                    <span>Change {''}</span>
                    your plan frequency
                  </li>
                </S.LinksList>
              </S.BodyItems>
              <S.BodyItems>
                <S.ItemNumber>3</S.ItemNumber>
                <S.SubTitle>
                  If you prefer to, you can call us on
                  {''} <a href="tel:+1 (646) 699-3464">+1 (646) 699-3464</a>
                </S.SubTitle>
                <p>
                  We realise how important it is for you to stay in control.
                  We're always happy to help
                </p>
              </S.BodyItems>
            </S.Body>
          </S.Container>
        </S.Wrapper>
      </Modal>
    )
  );
};

export default SubPopupV2;
